
@import "../variables.scss";
@import "../scss/mixins/media-queries";

.container{
    display:flex;
    width: 100%;
    overflow:hidden;
}
.content{
    position: relative;
    margin:0 auto;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

@include tablet() {

}

@include desktop() {
    .content{
        max-width: 1440px;
    }
}