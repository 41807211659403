@import "../variables.scss";
@import "../scss/mixins/media-queries";

.modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width:100vw;
    height: 100vh;
    z-index: 2;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    background: $white;
    cursor: default;

    .wrapper {
        img {
            padding: 1rem;
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }
    &-close{
        cursor: pointer;
        position: absolute;
        top:1rem;
        right:1rem;
    }

}
@include desktop() {
    .modal{
        flex-direction: row;
        &:has(.art-info){
            .wrapper {
                width: 75%;
            }
        }
    }
}