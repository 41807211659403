@import "../../variables.scss";
@import "../mixins/media-queries";

* {
    margin: 0;
    padding: 0;
    font-family: 'Amatic SC', cursive;
    box-sizing: border-box;
}

body {

    padding-top: 5rem;
    background-color: $white;
}

a {
    color: $black;
}

.page {
    position: relative;
    width: 80vw;
    margin: 0 auto;
}

.fade {
    &-enter {
        position: absolute;
        opacity: 0;
        z-index: 1;

        &-active {
            opacity: 1;


            transition: opacity 350ms ease-out;
        }
    }

    &-exit {
        position: absolute;
        opacity: 1;

        &-active {
            opacity: 0;

            transition: opacity 350ms ease-out;
        }
    }
}

.modal-open {

    &-enter {
        position: fixed;
        opacity: 0;
        z-index: 1;

        &-active {
            opacity: 1;

            transition: all 350ms ease-out;
        }
    }

    &-exit {
        position: fixed;
        opacity: 1;

        &-active {
            opacity: 0;
            transition: all 350ms ease-out;
        }
    }
}

.no-scroll {
    overflow: hidden;
    height: 100vh;
}

.transparent-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: transparent;
    z-index: 2;
}

@include desktop() {
    html {
        margin-left: calc(100vw - 100%);
        margin-right: 0;
    }
}