@import "../variables.scss";
@import "../scss/mixins/media-queries";

.grid-list {
    display: grid;
    grid-template-columns: auto;
    position: relative;
    width:100%;
}

@include tablet() {}

@include desktop() {
    .grid-list {
        display: grid;
        grid-template-columns: 50% 50%;
    }
}