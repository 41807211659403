@import "../variables.scss";
@import "../scss/mixins/media-queries";

.about-page{
    h2{
        font-size: 3em;
    }
    p{
        margin: 1rem 1rem;
        max-width: 72ch;
        font-size: 1.4rem;
        font-weight: bold;
    }
    img{
        width: 15rem;
        &:first-of-type{
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3)
        }
    }
    
    @include desktop() {
        p{
            margin: 1rem 5rem;
            max-width: 72ch;
            font-size: 1.7rem;
        }
    }
}
