@import "../variables.scss";
@import "../scss/mixins/media-queries";

.art-info{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    width: fit-content;
    align-self: center;
    height: auto;
    padding: 1rem;
    margin: 2rem;
    width: min-content;
    &::before{
        content:"";
        border: solid 1px black;
        position: absolute;
        top:0;
        left:0;
        height: 100%;
        width:100%;
        transform: skew(-0.5deg, -1deg);

    }
    span{
        margin-top: 1rem;
        font-size: 1.2rem;
    }
    a{
        position: relative;
        margin-top: 1rem;
        font-size: 1.2rem;
        font-weight: bold;
        text-decoration: none;
        cursor: pointer;
        z-index: 10;
        transform:scaleX(1) skew(-0.5deg, -1deg);
        &::after{
            content:"";
            transform:scaleX(0);
            
            position: absolute;
            bottom:0;
            left:0;
            width:100%;
            height:0.05rem;
            background-color: #000;
            transition: transform 0.5s;
            transition-timing-function:cubic-bezier(0.65, 0, 0.15, 0.61);
        }
        &:hover{
            &::after{
                transform:scaleX(1) skew(-0.5deg, -1deg);
            }
            
        }
    }
}

@include tablet() {
    .art-info{
        
    }
}

@include desktop() {
    .art-info{
        font-size: 3rem;
        span{
            font-size: 2rem;
        }
        a{
            font-size: 2rem;
            width: min-content;
        }
    }
}
