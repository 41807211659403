@import "../variables.scss";
@import "../scss/mixins/media-queries";

.art {
    position: relative;
    cursor: pointer;
    margin: 2rem;
    box-shadow: 0px 0px 2px 0px rgba(#000000, .3);
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(0.65, 0, 0.15, 0.61);

    >img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
        overflow:hidden;
        pointer-events: none;

    }

    &-description {
        position: absolute;
        font-size: 1.4rem;
        width: 100%;
        bottom: 0px;
        background: rgba(#FFF, .9);
        text-align: center;
        color: black;
        padding: .5rem 1rem;
        transition: all 0.5s;
        z-index: 1;
    }
}

@include desktop() {
    .art {

        &:hover,
        &:focus-visible {
            .art-description {
                opacity: 0;
            }
            box-shadow: 0px 0px 15px 0px rgba(#000000, .2);
        }
        &-description {
            font-size: 2rem;
        }
    }
}