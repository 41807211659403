@import "../variables.scss";
@import "../scss/mixins/media-queries";

.grid-header {
    font-size: 3.5rem;
    margin: 2rem;
    text-align: center;
    line-height: 100%;
    cursor: default;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

@include tablet() {}

@include desktop() {
    .grid-header {
        font-size: 5rem;
    }
}