
@import "../variables.scss";
@import "../scss/mixins/media-queries";
.header{
    position: relative;
    font-size: 3rem;
    text-align: center;
    color:$almostblack;
    background: $white;
    position: sticky;
    padding-bottom: 2rem;
    z-index: 1;
    h1{
        cursor: default;        
    }
}

menu{
    cursor:default;
    font-size: 2rem;
    display: flex;
    list-style: none;
    justify-content: center;
    a {
        cursor: pointer;
        margin: 0px 1rem 0px 1rem;
        position: relative;
        text-decoration: none;
        &::after{
            content:"";
            //width:0px;
            transform:scaleX(0);
            position: absolute;
            bottom:0;
            left:0;
            width:100%;
            height:0.05rem;
            background-color: #000;
            transition: transform 0.5s;
            transition-timing-function:cubic-bezier(0.65, 0, 0.15, 0.61);
        }
        &:hover{
            &::after{
                transform:scaleX(1);
            }
            
        }
       
    }
}
.instagram{
    > *{
        transition: transform 0.5s;
        transition-timing-function:cubic-bezier(0.65, 0, 0.15, 0.61);
    }
    
    &::after{
        content:none;
    }    
    &:hover{
       > *{
        transform:scale(1.2)
       } 
    }
}
.active{
    &::after{
        transform:scaleX(1);
    }
}

@include tablet() {
    .header{
        font-size: 5rem;
    }
    menu{
        font-size: 3rem;
    }
}

@include desktop() {
    .header{
        font-size: 10rem;
    }
    menu{
        font-size: 3rem;
    }
}