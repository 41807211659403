@import "../variables.scss";
@import "../scss/mixins/media-queries";

.gridContent {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem;
    font-size:1.5rem;
    text-align: center;
    img{
        width: 100%;
    }
    h2{
        font-size: 3.5rem;
        text-align: center;
        cursor: default;
        margin-bottom: 1.5rem;
    }
}

@include desktop() {
    .gridContent {
        font-size:2rem;
        h2{
            font-size: 5rem;
            text-align: center;
            cursor: default;
            margin-bottom: 1.5rem;
        }
    }
}