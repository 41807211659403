@import "../variables.scss";
@import "../scss/mixins/media-queries";

.image-loading {
    width: 100%;
    height: 181px;
    background: linear-gradient(270deg, #acacac,#e8e8e8);
    background-size: 400% 400%;

    animation: GradientPulse 3s ease infinite;
}

@include tablet() {
    .image-loading {
        width: 100%;
        height: 400px;
    }
}

@include desktop() {
    .image-loading {
        width: 100%;
        height: 442px;
    }
}


@keyframes GradientPulse {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}